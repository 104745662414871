import {IControllers, Loader} from "../loader";
import {IController} from "../controller";

export class NoticiaLoader extends Loader {
    /* STATIC */
    public static override async build(): Promise<IControllers> {
        const onload: IController[] = [];
        if (window.location.pathname.endsWith(".html") && window.tipologia === "noticia") {
            const [
                {NoticiaController},
            ] = await Promise.all([
                import(/* webpackChunkName: "encuesta" */ `../controllers/noticia`),
            ]);

            onload.push(...[
                NoticiaController,
            ])
        }
        return {
            ...await super.build(),
            onload,
        };
    }

    /* INSTANCE */
}
