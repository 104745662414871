import {IControllers, Loader} from "../loader";
import {IController} from "../controller";

export class EncuestasLoader extends Loader {
    /* STATIC */
    public static override async build(): Promise<IControllers> {
        const onload: IController[] = [];
        if (window.location.pathname.startsWith("/encuestas/")) {
            const [
                {EncuestaController},
            ] = await Promise.all([
                import(/* webpackChunkName: "encuesta" */ `../controllers/encuesta`),
            ]);

            onload.push(...[
                EncuestaController,
            ])
        }
        return {
            ...await super.build(),
            onload,
        };
    }

    /* INSTANCE */
}
